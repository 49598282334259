/* eslint-disable max-classes-per-file */
/* eslint-disable react/no-multi-comp */

import React, { Component } from "react";
import { HashLink } from "react-router-hash-link";
import { Container, Grid, Menu, Segment, Visibility } from "semantic-ui-react";
import MobileNav from "./MobileNav";

class DesktopContainer extends Component {
  state = {};

  hideFixedMenu = () => this.setState({ fixed: false });
  showFixedMenu = () => this.setState({ fixed: true });

  render() {
    const { fixed }: any = this.state;

    return (
      <Visibility
        once={false}
        onBottomPassed={this.showFixedMenu}
        onBottomPassedReverse={this.hideFixedMenu}
      >
        <Segment inverted textAlign="center" vertical>
          <Menu
            fixed={fixed ? "top" : undefined}
            inverted={!fixed}
            pointing={!fixed}
            secondary={!fixed}
            size="large"
          >
            <Container>
              <Menu.Item as={HashLink} to="/#who">
                Who We Are
              </Menu.Item>
              <Menu.Item as={HashLink} to="/#what">
                What We Do
              </Menu.Item>
              <Menu.Item as={HashLink} to="/#why">
                Why Your Wise Monkeys
              </Menu.Item>
              <Menu.Item as={HashLink} to="/demos#top">
                Translation Player Demos
              </Menu.Item>
              <Menu.Item as={HashLink} to="/contact#top">
                Contact Us
              </Menu.Item>
            </Container>
          </Menu>
        </Segment>
      </Visibility>
    );
  }
}

const NavBar = () => (
  <Segment basic inverted style={{ margin: 0 }}>
    <Grid inverted centered>
      <Grid.Row only="computer">
        <Grid.Column width={16}>
          <DesktopContainer />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row only="tablet mobile">
        <Grid.Column width={16}>
          <MobileNav />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Segment>
);

export default NavBar;
