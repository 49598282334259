import React, { Fragment } from "react";
import {
  Grid,
  Segment,
} from "semantic-ui-react";
import DemoPageHeader from "./DemoPageHeader";
import DemoPageInfo from "./DemoPageInfo";
import Footer from "../../components/Footer";
import ContactModal from "../../components/ContactModal";
import VideoPlayer from "../../components/VideoPlayer";

const DemoPage = () => {
  return (
    <Fragment>
      <Segment basic inverted style={{marginTop:0, marginBottom: 0}}>
        <Grid stackable centered>
          <Grid.Column mobile={12} tablet={12} computer={10}>
            <DemoPageHeader />
            <VideoPlayer />
            <DemoPageInfo />
            <ContactModal />
          </Grid.Column>
        </Grid>
      </Segment>
      <Footer />
    </Fragment>
  );
};

export default DemoPage;
