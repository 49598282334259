import videojs, { VideoJsPlayerOptions } from "video.js";

export const playerOptions: VideoJsPlayerOptions = {
    aspectRatio: '16:9',
    autoplay: false,
    controls: true,
    controlBar: {
        // volumePanel?: VolumePanelOptions | false,
        // playToggle?: false,
        // captionsButton: false,
        // chaptersButton?: false,
        // subtitlesButton?: false,
        // remainingTimeDisplay?: false,
        // progressControl?: ProgressControlOptions | false,
        // fullscreenToggle?: false,
        playbackRateMenuButton: false,
        pictureInPictureToggle: false,
        // currentTimeDisplay?: false,
        // timeDivider?: false,
        // durationDisplay?: false,
        // liveDisplay?: false,
        // seekToLive?: false,
        // customControlSpacer?: false,
        // descriptionsButton?: false,
        // subsCapsButton?: false,
        // audioTrackButton?: false,
    },
    html5: {
        vhs: {
            overrideNative: !videojs.browser.IS_SAFARI,
            limitRenditionsByPlayerDimensions: true
        },
        nativeAudioTracks: videojs.browser.IS_ANY_SAFARI,
        nativeVideoTracks: videojs.browser.IS_ANY_SAFARI
    }
};