import React from "react";
import { Grid, Image } from "semantic-ui-react";
import logo from "../../assets/logotext.svg";

const DemoPageHeader = () => {
  return (
    <Grid.Row>
      <Image centered size="large" src={logo} />
    </Grid.Row>
  );
};

export default DemoPageHeader;
