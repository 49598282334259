import videojs from 'video.js'

export class VideoSourceObject implements videojs.Tech.SourceObject {
    src: string;
    type: MimeType["type"];
    
    constructor (src: string, type: MimeType["type"]) {
        this.src = src;
        this.type = type;
    }
}