import { FC, Fragment, useEffect, useRef, useState } from "react";
import { Grid } from "semantic-ui-react";
import videojs, { VideoJsPlayer } from "video.js";
import { VideoSourceObject } from "../models/VideoModels";
import { playerOptions } from "../options/PlayerOptions";
import MobileVideoMenu from "./MobileVideoMenu";
import VideoMenu from "./VideoMenu";

const VideoPlayer: FC = () => {
  const vidInit = new VideoSourceObject(
    "final/arenaPromo/arenaSource.m3u8",
    "application/x-mpegURL"
  );

  const [src, setSrc] = useState(vidInit);
  const [player, setPlayer] = useState<VideoJsPlayer | null>(null);
  const videoPlayerRef = useRef(null); // Instead of ID

  const videoSrc: VideoSourceObject = {
    src: `https://d2rb9lk3z0fqid.cloudfront.net/${src.src}`,
    type: src.type,
  };

  useEffect(() => {
    if (videoPlayerRef) {
      const newPlayer = videojs(videoPlayerRef.current, playerOptions, () => {
          newPlayer.src(videoSrc);
          newPlayer.preload(true);
          newPlayer.on("ended", () => {
            console.log("ended");
          });
          console.log("Player Ready");
      });
      setPlayer(newPlayer);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (player && player.currentSource().src !== videoSrc.src) {
      player.src(videoSrc);
    }
    // eslint-disable-next-line
  }, [src]);

  return (
    <Fragment>
      <Grid>
        <Grid.Row>
          <Grid.Column
            textAlign="center"
            only="computer"
            width={3}
            style={{ paddingRight: 0 }}
          >
            <VideoMenu setSrc={setSrc} vertical />
          </Grid.Column>
          <Grid.Column width={16} only="tablet mobile">
            <MobileVideoMenu setSrc={setSrc} />
          </Grid.Column>
          <Grid.Column tablet={16} computer={13} style={{ paddingLeft: 0 }}>
            <video
              playsInline
              ref={videoPlayerRef}
              className="video-js vjs-big-play-centered"
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Fragment>
  );
};

export default VideoPlayer;
