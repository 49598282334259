import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import App from './components/layout/App';
import 'semantic-ui-css/semantic.min.css'
import 'video.js/dist/video-js.css'
import './assets/styles.css'

export const history = createBrowserHistory();

ReactDOM.render(
  <Router history={history}>
    <App />
  </Router>,
  document.getElementById('root')
);

