import React, { useState } from "react";
import { HashLink } from "react-router-hash-link";
import { Container, Icon, Menu, Segment, Sidebar } from "semantic-ui-react";

const MobileNav = () => {
  const [visible, setVisible] = useState(false);

  return (
    <Segment inverted basic>
      <Icon
        circular
        size="big"
        name="sidebar"
        color="grey"
        link
        onClick={() => setVisible(!visible)}
      />
      <Container fluid>
        {/* START HERE */}
        <Sidebar
          as={Menu}
          animation="overlay"
          icon="labeled"
          direction="top"
          inverted
          onHide={() => setVisible(false)}
          vertical
          visible={visible}
          width="thin"
        >
          <Menu.Item as={HashLink} to="/#who">
            Who We Are
          </Menu.Item>
          <Menu.Item as={HashLink} to="/#what">
            What We Do
          </Menu.Item>
          <Menu.Item as={HashLink} to="/#why">
            Why Your Wise Monkeys
          </Menu.Item>
          <Menu.Item as={HashLink} to="/demos#top">
            Translation Player Demos
          </Menu.Item>
          <Menu.Item as={HashLink} to="/contact#top">
            Contact Us
          </Menu.Item>
        </Sidebar>
      </Container>
    </Segment>
  );
};

export default MobileNav;
