import React, { Fragment } from "react";

const ContactForm = () => (
  <Fragment>
    <iframe
      title='Contact Us'
      style={{ height: "700px", width: "99%", border: "none", padding: "auto" }}
      src="https://forms.zohopublic.com/runj8mpfly/form/ContactForm/formperma/IJMVWbg1FM75R3lK6YcFQM0AJu8krmI7jwSu1-3OUls?zf_rszfm=1"
    ></iframe>
  </Fragment>
);

export default ContactForm;
