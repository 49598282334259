import { Fragment } from 'react';
import { Route, RouteComponentProps, withRouter } from 'react-router-dom';
import HomePage from '../../views/home/HomePage';
import DemoPage from '../../views/demo/DemoPage';
import ContactPage from '../../views/contact/ContactPage';
import NavBar from '../../views/navbar/NavBar';

const App: React.FC<RouteComponentProps> = () => {
  return (
    <Fragment>
      <NavBar />
      <Route exact path="/" component={HomePage} />
      <Route path="/demos" component={DemoPage} />
      <Route path="/contact" component={ContactPage} />
    </Fragment>
  );
}

export default withRouter(App);
