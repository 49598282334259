import React, { Fragment, useState } from "react";
import { Modal, Button } from "semantic-ui-react";

const ContactModal = () => {
  const [open, setOpen] = useState(false);

  return (
    <Fragment>
      <Modal
        basic
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        size="small"
        trigger={<Button basic inverted style={{marginLeft: 10}}>Contact Us</Button>}
      >
        <Modal.Content>
          <iframe
            title="Contact Us"
            style={{
              height: "700px",
              width: "99%",
              border: "none",
              padding: "auto",
            }}
            src="https://forms.zohopublic.com/runj8mpfly/form/ContactForm/formperma/IJMVWbg1FM75R3lK6YcFQM0AJu8krmI7jwSu1-3OUls?zf_rszfm=1"
          ></iframe>
        </Modal.Content>
      </Modal>
    </Fragment>
  );
};

export default ContactModal;
