import React, { Dispatch, FC, SetStateAction } from "react";
import { Dropdown, Menu } from "semantic-ui-react";
import { VideoSourceObject } from "../models/VideoModels";

interface IProps {
  setSrc: Dispatch<SetStateAction<VideoSourceObject>>;
  vertical?: boolean;
}

const MobileVideoMenu: FC<IProps> = ({ setSrc, vertical }) => {
  const handleSelection = (src: string, type: MimeType["type"]) => {
    const newSrc: VideoSourceObject = {
      src: src,
      type: type,
    };
    setSrc(newSrc);
  };

  return (
    <Dropdown as='h3' text='Translation Demos...'>
    <Dropdown.Menu>
      <Menu.Item
        name="Virtual Arena"
        onClick={() =>
          handleSelection(
            "final/arenaPromo/arenaSource.m3u8",
            "application/x-mpegURL"
          )
        }
      />
      <Menu.Item
        onClick={() =>
          handleSelection(
            "final/adoptTogetherPromo/adoptTogetherPromo.m3u8",
            "application/x-mpegURL"
          )
        }
      >
        AdoptTogether
      </Menu.Item>
      <Menu.Item
        name="Magic of Nathan Burton"
        onClick={() =>
          handleSelection(
            "final/arenaPromo/burtonPromo.m3u8",
            "application/x-mpegURL"
          )
        }
      />
    </Dropdown.Menu>
  </Dropdown>
  );
};

export default MobileVideoMenu;
