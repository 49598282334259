import React from 'react'
import { Feed, Grid, Header, Icon, Segment } from 'semantic-ui-react'

const DemoPageInfo = () => {
    return (
        <Grid.Row>
              <Segment inverted basic>
                <Header>Welcome to our Demo Page</Header>
                <Feed>
                  <Feed.Event>
                    <Feed.Label>
                      <Icon color="grey" name="headphones" />
                    </Feed.Label>
                    <Feed.Content>
                      The Headphone icon at bottom right of player lets you
                      select a new audio language 
                    </Feed.Content>
                  </Feed.Event>
                  <Feed.Event>
                    <Feed.Label>
                      <Icon color="grey" name="closed captioning" />
                    </Feed.Label>
                    <Feed.Content>
                      The Caption icon at bottom right of player lets you select
                      a new subtitle language
                    </Feed.Content>
                  </Feed.Event>
                </Feed>
                <Header as="h3">
                  If you are interested in learning more, please reach out
                  through our contact form.
                </Header>
              </Segment>
            </Grid.Row>
    )
}

export default DemoPageInfo
