import { Dispatch, FC, SetStateAction } from "react";
import { Menu } from "semantic-ui-react";
import { VideoSourceObject } from "../models/VideoModels";

interface IProps {
  setSrc: Dispatch<SetStateAction<VideoSourceObject>>;
  vertical?: boolean;
}

const VideoMenu: FC<IProps> = ({ setSrc, vertical }) => {
  const handleSelection = (src: string, type: MimeType["type"]) => {
    const newSrc: VideoSourceObject = {
      src: src,
      type: type,
    };
    setSrc(newSrc);
  };

  return (
    <Menu inverted fluid vertical={vertical} tabular={vertical}>
      <Menu.Header as="h4">Translation Demos</Menu.Header>
      <Menu.Item
        content="Virtual Arena"
        onClick={() =>
          handleSelection(
            "final/arenaPromo/arenaSource.m3u8",
            "application/x-mpegURL"
          )
        }
      />
      <Menu.Item
        content='AdoptTogether'
        onClick={() =>
          handleSelection(
            "final/adoptTogetherPromo/adoptTogetherPromo.m3u8",
            "application/x-mpegURL"
          )
        }
      />
      <Menu.Item
        content="Magic of Nathan Burton"
        onClick={() =>
          handleSelection(
            "final/arenaPromo/burtonPromo.m3u8",
            "application/x-mpegURL"
          )
        }
      />
    </Menu>
  );
};

export default VideoMenu;
