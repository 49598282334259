import React from 'react'
import { Container, Grid, Header, List, Segment } from 'semantic-ui-react'

const Footer = () => {
    return (
        <Segment inverted vertical style={{ padding: "5em 0em" }}>
        <Container>
          <Grid divided inverted stackable>
            <Grid.Row centered>
              <Grid.Column width={13}>
                <Header inverted as="h4" content="Your Wise Monkeys&#8482;" />
                <List link inverted>
                  <List.Item style={{ marginBottom: 10 }}>
                    <em>
                      All Content Copyright<sup>&copy;</sup> 2021 Your Wise
                      Monkeys, Inc, All Rights Reserved
                    </em>
                  </List.Item>
                  <List.Item as="a">Terms and Conditions</List.Item>
                </List>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Segment>
    )
}

export default Footer
