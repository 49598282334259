import React, { Fragment } from "react";
import {
  Container,
  Grid,
  Header,
  Image,
  List,
  Segment,
} from "semantic-ui-react";
import logo from "../../assets/logotext.svg";
import Footer from "../../components/Footer";
import ContactForm from "./ContactForm";

const ContactPage = () => (
  <Fragment>
    <Segment basic inverted style={{ margin: 0 }}>
      <Grid centered stackable>
        <Grid.Row centered style={{ marginTop: 20 }}>
          <Grid.Column width={8}>
            <Image src={logo} />
          </Grid.Column>
          <Grid.Column width={16} textAlign="center">
            <Header inverted size="huge" style={{ marginTop: 20 }}>
              <em>Ready to use our SPEAK TO THE WORLD&#8482; Platform?</em>
              <p>
                Contact us and we will help you get your message to EVERYONE,
                EVERYWHERE.
              </p>
            </Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ marginTop: 30 }}>
          <Grid.Column mobile={16} computer={6} style={{ marginTop: 60 }}>
            <Header inverted size="large">
              Reach out to us by email, or send us a quick note through our
              contact form.
            </Header>
            <Header textAlign="center" inverted size="large">
              <a href={"mailto:info@YourWiseMonkeys.com"} target="_blank" rel='noreferrer'>
                info@YourWiseMonkeys.com
              </a>
            </Header>
          </Grid.Column>
          <Grid.Column mobile={16} computer={6}>
            <ContactForm />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
    <Footer />
  </Fragment>
);

export default ContactPage;
