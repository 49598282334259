/* eslint-disable max-classes-per-file */
/* eslint-disable react/no-multi-comp */

import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import {
  Button,
  Container,
  Grid,
  Header,
  Image,
  List,
  Segment,
} from "semantic-ui-react";
import logo from "../../assets/logotext.svg";

/* Heads up!
 * HomepageHeading uses inline styling, however it's not the best practice. Use CSS or styled
 * components for such things.
 */
const HomepageHeading = () => (
  <Segment inverted style={{ marginTop: 0}}>
    <Container>
      <Image fluid src={logo} className="logo" style={{marginBottom: 20}} />
    </Container>
    <Container text>
      <Header size='small' textAlign='center' inverted style={{fontWeight: 300, fontStyle: 'italic'}} >
      “Telling YOUR STORY to the ENTIRE WORLD<br/> is very DIFFERENT than simply telling your story…”
      </Header>
      <Header size='small' inverted style={{fontWeight: 400}}>
      95% of the over 1 billion monthly users of Google Translate are outside the U.S.A.; and more than half of the 2.5 billion Facebook users post in a language other than English.&sup1;
      </Header>
      <Header size='small' textAlign='right' inverted style={{fontWeight: 400 , paddingBottom: 20 }} >
      &mdash;“How many people are NOT understanding what you are saying?”
      </Header>
    </Container>
    <div id='who' />
  </Segment>
);

const HomePage = () => (
    <Fragment>
    <HomepageHeading />
    <Segment vertical>
      <Grid celled="internally" centered stackable verticalAlign="middle">
        <Grid.Row style={{paddingTop: 30}}>
          <Grid.Column width={12}>
            <Header size='huge' >
              Your Wise Monkeys&#8482;
            </Header>
            <Header size='medium' style={{fontWeight: 400, marginTop: 0, marginBottom: 30}}>
              You have something to say and we are here to help you say it to <strong>EVERYONE – ANYWHERE</strong>.
              <br/>At <strong>Your Wise Monkeys</strong> we help you tell your story to anyone and everyone, anywhere and everywhere &mdash;<br /> with our <strong>SPEAK TO THE WORLD&#8482;</strong> Platform, you can do just that.
            </Header>
          </Grid.Column>
        </Grid.Row>
        {/* Section - What We Do */}
        <div id='what' />
        <Grid.Row style={{paddingTop: 30}}>
            <Grid.Column width={12}>
                <Header size='huge' style={{fontWeight: 700}}>
                    What We Do
                </Header>
                <Header size='medium' style={{fontWeight: 300, fontStyle: 'italic'}}>
                    “We are more than a translation platform; we connect people around the world to you and you to them.” 
                </Header>
                <Header size='medium' style={{fontWeight: 400}}>
                    We take your message and we make it accessible and understandable to EVERYONE.  Our translation service platform utilizes a proprietary video player that allows your audience full control of <strong>what</strong> they see and hear and <strong>how</strong> they see and hear it.  We can provide access to your content in over 100 languages and we make it possible for your viewers to hear your message in their own language and dialect and read closed captioning in their language.  Your audience can also select the option to have a Sign language interpreter appear next to your video content to provide side by side viewing with human Sign language translation. 
                </Header>
                <Header size='medium' style={{fontWeight: 400}}>
                    <strong><em>SPEAK TO THE WORLD™</em></strong>, our translation platform, works for your pre-recorded and LIVE content for your virtual conferences, events, shows and productions, your commercials, training videos, seminars, educational curriculum, informational campaigns, customer relations content, and your <strong>in-person live events, meetings and productions</strong>. 
                </Header>
                <Header size='medium' style={{fontWeight: 400, marginBottom:30}}>
                    Enhance your content and messaging with Transcription, Contextual Audio and Text Translation, Subtitles and Sign Language Interpretation.
                </Header>
            </Grid.Column>
        </Grid.Row>
        {/* Section - Why Your Wise Monkeys */}
        <Grid.Row id={'why'}>
        <Grid.Column width={12}>
                <Header size='huge' style={{fontWeight: 700, marginTop: 20}}>
                    Why Your Wise Monkeys?
                </Header>
                <Header size='medium' style={{fontWeight: 400}}>
                    We provide so much more than translation services – our award winning team of producers, creators, and directors have helped create and produce some of the top entertainment and content around the world.  We know what it means to set the benchmark for competition and for quality.  We’ve worked with numerous Fortune 500 and Fortune’s top 100 companies, global leaders in entertainment, and some of the most innovative and world changing non-profits and charities. Our team has the experience and understanding to help our clients excel in any market, and now <em>anywhere in the world.</em>
                </Header>
                <Header size='medium' style={{fontWeight: 400}}>
                    Yes, we provide translation services, and we can provide full messaging and branding support, full virtual and live production services, and full creative direction and guidance for your messaging and content creation for any platform, live or virtual.  
                    <br /><hr /><em><strong>If you have something to say – we can help you say it better and to everyone, everywhere.</strong></em>
                </Header>
                <Header size='medium' style={{fontWeight: 400, marginBottom: 20}}>
                    Many industries, such as banking, have new legally required compliance standards&sup2; when it comes to providing equitable access to services to non-English speaking or Limited English Proficiency (LEP) consumers.  Your Wise Monkeys can help you meet and exceed those compliance standards and requirements, now, and ahead of your competition.  
                </Header>
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column width={8} style={{marginTop: 20}}>
                <Header size='large'>
                    <em>Ready to use our SPEAK TO THE WORLD&#8482; Platform?</em>
                    <p>Contact us and we will help you get your message to EVERYONE, EVERYWHERE.</p>
                    <Container fluid textAlign='center'>
                        <Button size='huge' as={NavLink} to='/contact'>Contact Us</Button>
                    </Container>
                </Header>
            </Grid.Column>
        </Grid.Row>

      </Grid>
    </Segment>

    <Segment inverted vertical style={{ padding: "5em 0em" }}>
      <Container>
        <Grid divided inverted stackable>
          <Grid.Row centered>
            <Grid.Column width={13}>
              <Header inverted as="h4" content="Your Wise Monkeys&#8482;" />
              <List link inverted>
                <List.Item style={{marginBottom: 10}}><em>All Content Copyright<sup>&copy;</sup> 2021 Your Wise Monkeys, Inc, All Rights Reserved</em></List.Item>
                <List.Item as='a' href='https://www.usatoday.com/story/tech/2020/02/05/translation-tech-solutions-language-barriers-google-translate-interpreter/4596091002/' target='_blank' ><sup>(1)</sup>Ref: USA Today, Feb 5, 2020</List.Item>
                <List.Item as='a' href='https://files.consumerfinance.gov/f/documents/cfpb_lep-statement_2021-01.pdf' target="_blank" style={{marginBottom: 10}}><sup>(2)</sup>Ref: Bureau of Consumer Financial Protection</List.Item>
                <List.Item as='a'>Terms and Conditions</List.Item>
              </List>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  </Fragment>
);

export default HomePage;
